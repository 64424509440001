import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY} from "../../../modules/constants";

interface Props {
}

const BlockedContentPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='BLOCKED_CONTENT_POLICY'>
        <p>At {APP_COMPANY}, we are committed to providing a secure, respectful, and lawful online environment for all users. Although users cannot upload content to our Cherryx.ai platform ("application"), they can chat and generate AI-created content, which is accessible only to the specific user in their private space and is not publicly accessible. This Prohibited Content Policy outlines categories of content strictly forbidden on our application.
        </p>
      <h4>1. Prohibited Content
      </h4>
      <p>This policy applies to all content, including but not limited to text, images, videos, and any other AI-generated content created on our platform.
      </p>
      <ul>
        <li><b>A) Illegal Content:</b> Content that violates local, national, or international laws and regulations is strictly prohibited. This includes, but is not limited to, content related to illegal drugs, weapons, violence, or activities that advocate or endorse illegal actions.
        </li>
        <li><b>B) Hate Speech and Discrimination:</b> Content that promotes hate speech, discrimination, or harassment based on race, ethnicity, nationality, religion, gender, sexual orientation, disability, or any other protected characteristic is not tolerated.
        </li>
        <li><b>C) Violence and Harm:</b> Content that encourages, glorifies, or promotes violence, self-harm, or harm to others is strictly prohibited. This includes content that endorses suicide, terrorism, or any form of harm.
        </li>
        <li><b>D) Child Exploitation:</b> Content that exploits or poses a danger to minors, including but not limited to child pornography, sexual exploitation, or any form of harm or harassment towards minors, is unequivocally disallowed.
        </li>
        <li><b>E) Content Resembling Minors:</b> It is strictly prohibited to attempt to generate AI content resembling minors. We are committed to ensuring that AI-generated content on our platform does not bear any resemblance to minors or exploit their likeness. Any such attempts will be promptly reviewed and removed according to our Content Moderation procedures (see section 3).
        </li>
        <li><b>F) Infringement on Privacy and Copyright:</b> Content that infringes upon the privacy, copyrights, trademarks, or intellectual property rights of individuals or entities is not permitted. This includes sharing personal information without consent, pirated content, or any unauthorized use of copyrighted material.
        </li>
        <li><b>G) Impersonation and Celebrity Content:</b> Deceptive or harmful impersonation of real individuals, public figures, or celebrities is prohibited. This includes any misleading attempts to impersonate or misrepresent others.
        </li>
      </ul>
      <h4>2. Content Responsibility
      </h4>
      <p>As a user of the application, you are solely responsible for the output generated by the AI Companions through text messages, images, and other media. The AI Companions generate responses based on your interactions and the parameters you set. You understand and agree that {APP_COMPANY} does not control or endorse the content generated by the AI Companions. Therefore, you acknowledge full responsibility for the AI-generated output and your actions while using the application.
      </p>
      <ul>
        <li>You must ensure that your interactions with the AI Companions comply with applicable laws, regulations, our Terms and Policies, especially this Prohibited Content Policy. You must not engage in any illegal, unethical, or harmful activities through the application.
        </li>
      </ul>
      <h4>3. Content Moderation
      </h4>
      <p>While conversations between users and AI Companions are generally confidential, we have implemented a content moderation filter based on our proprietary technology to ensure compliance with our Terms and Policies, particularly this Prohibited Content Policy. If the moderation filter detects any content that violates our terms, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account. This measure is implemented to maintain a respectful and secure environment for all users. We strive to balance privacy and community standards and appreciate your cooperation in adhering to our guidelines.
      </p>
      <h4>4. Content Removal and Account Suspension
      </h4>
      <p>Violations of this Prohibited Content Policy may result in content removal, account suspension, or legal action as appropriate.
      </p>
      <h4>5. Contact Information
      </h4>
      <p>If you notice any violations of these terms, encounter inappropriate content, or have any questions regarding our Prohibited Content Policy, please contact us at <a
        href="mailto:support@cherryx.ai" target='_blank'>support@cherryx.ai</a>.
      </p>
      <h4>6. Termination</h4>
      <p>We reserve the right to suspend or terminate the use of the application by anyone engaged in the suspected infringement of the above-stated policies.
      </p>
    </LegalInformationPageWrapper>
  );
}

export default BlockedContentPolicy;