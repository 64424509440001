import {EAction, ELevel, ESender, IConversation, IMessage, IModel} from "../../../modules/rest";
import moment from "moment/moment";

export interface IConversationModel   {
  chats: IConversation[], messages: IMessage[], activeChat: IConversation,
}

export const  setConversationModel = (model: IModel) : IConversationModel => {
  const message: IMessage = {
    ad: null,
    sender: ESender.Model,
    text: model.welcomeMessageLocal as string,
    id: 1,
    photo: null,
    createdAt: moment().format('YYYY-MM-DD'),
  }
  const chats = [{model, lastMessage: message, id: model.id, action: EAction.None, counter: 0}]
  const messages = [message];
  const activeChat = {model, lastMessage: message, id: model.id, action: EAction.None, counter: 0}
  const res = {chats, messages, activeChat}
  localStorage.setItem('conversation', JSON.stringify(res));
  //@ts-ignore
  return res;
}