import React, {FC, useEffect} from 'react';
import ChatsListItem from "./ChatsListItem";
import {useNavigate, useParams} from "react-router-dom";
import ChatStore from "../../../../store/ChatStore";
import {observer} from "mobx-react";
import {runInAction } from "mobx";
import {HeaderSecondary} from "../../../../containers/Header";
import AppStore from "../../../../store/AppStore";
import {IConversationModel, setConversationModel} from "../../../HomePage/modules/utils";
import {IConversation} from "../../../../modules/rest";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  handleConversation: (conversation: IConversationModel) => void;
}

  const ChatsList: FC<Props> = observer(({className, handleConversation}) => {
  const {t} = useTranslation();
  const params = useParams()
  // const [searchLoading, setSearchLoading] = useState(false);
  // const [search, setSearch] = useState('');
  const navigate = useNavigate();

  // const handleSearch = (value: string) => {
  //   setSearch(value);
  //   setSearchLoading(false);
  // }

    useEffect(() => {
      const activeEl = document.getElementById('chats-list-item-active');
      setTimeout(() => activeEl?.scrollIntoView({ block: "center", behavior: "auto" }), 0)
    }, [ChatStore.activeChat?.id]);

  const onItemClick = (chat: IConversation) => () => {
    if (!AppStore.user?.id) {
      const conversation = setConversationModel(chat.model);
      handleConversation(conversation);
    } else if(Number(params.id) !== chat.id) {
      if(ChatStore.activeChat?.id !== chat.id) {
        runInAction(() => {
          ChatStore.messages = [];
          ChatStore.activeChat = undefined;
        })
        setTimeout(() => runInAction(() => {
          ChatStore.activeChat = chat;
        }), 0);
      }
      navigate(`/chat/${chat.id}`, {replace: true});
    }
  }

  return (
    <div className={className || ''}>
      <HeaderSecondary
        onClick={() => AppStore.toggleVisibleAside()}
        title='CHAT'
        coins
      />
      <h6 className='chats-section-title'><span>{t("CHAT")}</span></h6>
      <div className='chats-side-list-container'>
        <div className='chats-side-list'>
          {/*<div className='pb-3 position-relative d-flex justify-content-center px-12'>*/}
          {/*  <SearchInput*/}
          {/*    onSearch={handleSearch}*/}
          {/*    onStartTyping={() => setSearchLoading(true)}*/}
          {/*    small*/}
          {/*    className='w-100'*/}
          {/*  />*/}
          {/*  <Spinner loading={searchLoading} absolute style={{bottom: 2}}/>*/}
          {/*</div>*/}
          <div className='px-12'>
            {ChatStore.chats.map((item) => (
              <ChatsListItem
                onClick={onItemClick(item)}
                key={item.id}
                data={item}
                active={item.id === Number(ChatStore.activeChat?.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
})

export default ChatsList;