import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import {ICreateModelRequest} from "../../../../modules/rest";
import {personalityData} from "../../assets/modules/utils";
import {useTranslation} from "react-i18next";


interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step5: FC<Props> = ({onStepChange, data}) => {
  const {t} = useTranslation();

  const [personality, setPersonality] = useState(data?.personality || '');

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {personality} : undefined);
  }

  const disabled = !personality;

  return (
    <div className='create-ai-step'>
      <StepTitle title='CHOOSE_PERSONALITY' onStepChange={handleStepChange} nextDisabled={disabled} step={5}/>
      <div className='create-ai-list create-ai-personality'>
        {Object.entries(personalityData).map(([value, item]) => (
            <ActiveElement
              bordered
              small
              radio
              onClick={() => setPersonality(value)}
              key={value}
              active={personality === value}>
              <div className='create-ai-border-item'>
                <div className='create-ai-personality-item-icon'>{item.icon}</div>
                <div className='text-bold mt-2'>{t(item.title.toUpperCase())}</div>
                <p className='create-ai-personality-item-text text-dark'>{t(item.title.toUpperCase() + '_TEXT')}</p>
              </div>
            </ActiveElement>
          )
        )}
      </div>

      <StepFooter
        disabled={disabled}
        onBack={() => handleStepChange(-1)}
        onNext={() => handleStepChange(1)}
      />
    </div>
  );
}

export default Step5;