import React, {FC} from 'react';
import {observer} from "mobx-react";
import TopSection from "./components/TopSection";
import HomeList from "./components/HomeList";
import CraftSection from "./components/CraftSection";
import HomeFAQ from "./components/HomeFAQ";
import HomeDescription from "./components/HomeDescription";
import HomeFooter from "./components/HomeFooter";
import {useNavigate} from "react-router-dom";
import {API} from "../../modules/api";
import AppStore from "../../store/AppStore";
import {IModel} from "../../modules/rest";
import Header from "../../containers/Header";
import {setConversationModel} from "./modules/utils";
import {toast} from "react-toastify";

interface Props {
}

const HomePage: FC<Props> = observer(() => {
  const navigate = useNavigate();

  const handleAnswer = async (model: IModel) => {
    if (!AppStore.user?.id) {
      setConversationModel(model);
      navigate(`/chat`);
      return;
    }
    try {
      const res = await API.Models.startConversation(model.id);
      navigate(`/chat/${res.id}`);
    } catch (e: any) {
      toast.error(e)
    }
  }


  return (
    <>
      <Header className='header-main-mobile'/>
      <div className='home-page container'>
        <TopSection onAnswer={handleAnswer}/>
        <HomeList onAnswer={handleAnswer}/>
        <section className='home-craft'>
          <CraftSection className='w-100'/>
        </section>
        <section>
          <div className="d-flex flex-column flex-lg-row gap-4">
            <HomeFAQ/>
            <HomeDescription/>
          </div>
        </section>
        <HomeFooter/>
      </div>
    </>
  );
})

export default HomePage;