import {backendUrl} from "./api";
import moment from "moment";
import langs from '../translates/en/langs.json'
import {ESubscriptionStatus} from "./rest";

export const addZeroToTime = (time: number) => {
  return time < 10 ? `0${time}` : time
}

export const generatePassword = (length: number = 8) => {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const thumb = (id: string | undefined, size: number) => {
  if (!id) return '';
  return `${backendUrl}/thumb/${size}/${id}`;
};
export const thumbAvatar = (id: string | undefined, size: number) => {
  if (!id) return '';
  return `${backendUrl}/avatar/${size}/${id}`;
};

export const formatDate = (date?: string): string => {
  if(!date) return ''
  if (!moment(date).isValid()) return date
  return moment(date).toDate().toLocaleDateString();
};

export const getLanguageName = (ln: string): string => {
  //@ts-ignore
  const lang = langs[ln];
  if (!lang) return ln;
  return lang;
};

export const shuffleArr = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isSub = (status?: ESubscriptionStatus, pending?: boolean) => {
  return !!(status === ESubscriptionStatus.Active || (status === ESubscriptionStatus.Pending && pending));
}

export function lerp(x1: number, y1: number, x2: number, y2: number, x: number): number {
  if (x <= x1) return y1;
  if (x >= x2) return y2;
  const t = (x - x1) / (x2 - x1);
  return y1 + (y2 - y1) * t;
}

export const isMobile = /iPhone|iPad|iPod|Android|ie|blackberry|fennec/i.test(navigator.userAgent.toLowerCase()) || 'ontouchstart' in window;
