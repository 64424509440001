export type THttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type THeaders = Record<string, string>;

export type TValidationError = {
  key: string;
  name: string;
  error: string;
};

export type TResponse = {
  result?: any;
  error?: string;
  code?: number;
  validation?: TValidationError;
};

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public validation?: TValidationError;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = <T>(endpoint: string, payload?: object | FormData, fields?: string[]): Promise<T> => {
    return this.request('GET', endpoint, payload, fields);
  };

  post = <T>(endpoint: string, payload?: object | FormData, fields?: string[]): Promise<T> => {
    return this.request('POST', endpoint, payload, fields);
  };

  put = <T>(endpoint: string, payload?: object | FormData, fields?: string[]): Promise<T> => {
    return this.request('PUT', endpoint, payload, fields);
  };

  patch = <T>(endpoint: string, payload?: object | FormData, fields?: string[]): Promise<T> => {
    return this.request('PATCH', endpoint, payload, fields);
  };

  delete = <T>(endpoint: string, payload?: object | FormData, fields?: string[]): Promise<T> => {
    return this.request('DELETE', endpoint, payload, fields);
  };

  private request = <T>(
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<T> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (error: string, code: number, validation?: TValidationError) => {
        this.validation = validation;
        if (this.debug) console.error('Error', error, validation);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: { method: string; headers: THeaders; body?: FormData | string } = {
        method: method.toUpperCase(),
        headers: {
          accept: 'application/json',
        },
      };

      if (payload instanceof FormData) {
        payload.append('fields', fields.join(','));
        options.body = payload;
      } else {
        options.headers['content-type'] = 'application/json';
        // @ts-ignore
        payload['fields'] = fields;
        if (payload && method !== 'GET') options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers['authorization'] = 'Bearer ' + this.token;
      }

      this.statusCode = 0;
      this.validation = undefined;

      if (payload && method === 'GET') {
        endpoint += '?__payload=' + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug) console.log('Request', method, endpoint.split('?')[0], JSON.parse(JSON.stringify(payload)));

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data: TResponse) => {
              if (data.error) processReject(data.error, response.status, data.validation);
              else {
                if (this.debug) console.info('Result', data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  get Models(): Models {
    return (this.instances['Models'] as Models) ?? (this.instances['Models'] = new Models(this));
  }

  get Interract(): Interract {
    return (this.instances['Interract'] as Interract) ?? (this.instances['Interract'] = new Interract(this));
  }

  get Pricing(): Pricing {
    return (this.instances['Pricing'] as Pricing) ?? (this.instances['Pricing'] = new Pricing(this));
  }

  get Assets(): Assets {
    return (this.instances['Assets'] as Assets) ?? (this.instances['Assets'] = new Assets(this));
  }

  get Users(): Users {
    return (this.instances['Users'] as Users) ?? (this.instances['Users'] = new Users(this));
  }

  get SavedPaymentMethods(): SavedPaymentMethods {
    return (
      (this.instances['SavedPaymentMethods'] as SavedPaymentMethods) ??
      (this.instances['SavedPaymentMethods'] = new SavedPaymentMethods(this))
    );
  }

  get Tasks(): Tasks {
    return (this.instances['Tasks'] as Tasks) ?? (this.instances['Tasks'] = new Tasks(this));
  }

  get Params(): Params {
    return (this.instances['Params'] as Params) ?? (this.instances['Params'] = new Params(this));
  }

  get PaymentMethods(): PaymentMethods {
    return (
      (this.instances['PaymentMethods'] as PaymentMethods) ??
      (this.instances['PaymentMethods'] = new PaymentMethods(this))
    );
  }

  get Gallery(): Gallery {
    return (this.instances['Gallery'] as Gallery) ?? (this.instances['Gallery'] = new Gallery(this));
  }

  get Ad(): Ad {
    return (this.instances['Ad'] as Ad) ?? (this.instances['Ad'] = new Ad(this));
  }

  get Subscriptions(): Subscriptions {
    return (
      (this.instances['Subscriptions'] as Subscriptions) ?? (this.instances['Subscriptions'] = new Subscriptions(this))
    );
  }

  get Dictionary(): Dictionary {
    return (this.instances['Dictionary'] as Dictionary) ?? (this.instances['Dictionary'] = new Dictionary(this));
  }

  get Conversations(): Conversations {
    return (
      (this.instances['Conversations'] as Conversations) ?? (this.instances['Conversations'] = new Conversations(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IModel {
  id: number;
  owner?: IUser | null;
  name: string;
  gender: EGender;
  age: number;
  style?: EModelStyle;
  ethnicity?: EEthnicity;
  eyesColor?: EEyesColor;
  hairStyle?: EHairStyle;
  hairColor?: EHairColor;
  bodyType?: EBodyType;
  breastSize?: EBreastSize | null;
  buttSize?: EButtSize | null;
  occupation?: string;
  hobbies?: string[];
  personality?: string;
  relationship?: string;
  clothing?: string;
  image?: IAsset | null;
  about?: string | null;
  welcomeMessage?: string | null;
  prompt?: string | null;
  mainPhotoPrompt?: string | null;
  mainPhotoNegativePrompt?: string | null;
  mainPhotoBackgroundPrompt?: string | null;
  featuresPrompt?: string | null;
  languages?: ELanguage[] | null;
  translations?: Record<ELanguage, Record<string, any>> | null;
  tags?: ETag[];
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  firstName: string;
  welcomeMessageLocal?: string | null;
  aboutLocal?: string | null;
  occupationLocal?: string | null;
  personalityLocal?: string | null;
  relationshipLocal?: string | null;
  hobbiesLocal?: string[];
  mainPhotoImage: IAsset | null;
}

export interface ISavedPaymentMethod {
  id: string;
  type: ECardType;
  name: string;
  isDefault: boolean;
}

export interface IPrice {
  id: number;
  product?: IProduct;
  tier?: ITier;
  price: number;
}

export interface IAsset {
  id: string;
  name: string;
  mime: string;
  size: number;
  isS3?: boolean;
  noS3?: boolean;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  extra?: Record<string, any>;
  comment?: string | null;
  url: string;
  duration?: number | null;
  durationString?: string | null;
  resolution?: number[] | null;
  isNsfw: boolean;
  pose: EPose | null;
}

export interface IProduct {
  id: number;
  type: EPaymentType;
  period: number | null;
  credits: number | null;
  isRecommended: boolean;
}

export interface IPaymentMethod {
  id: number;
  name: string;
  alias?: string | null;
  icon: IAsset | null;
  category: EPaymentMethodCategory;
  gateway?: EPaymentGateway;
  isBillingInfo: boolean;
  countries?: ECountry[];
  isEnabled?: boolean;
  currency?: ECurrency | null;
  minAmount?: number | null;
  maxAmount?: number | null;
}

export interface ITransaction {
  id: string;
  extId: string | null;
  user?: IUser;
  type: EPaymentType;
  method: IPaymentMethod;
  status: ETransactionStatus;
  amount: number;
  amountUsd: number;
  currency: ECurrency;
  credits: number | null;
  period: number | null;
  details?: [];
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface ISubscription {
  id: number;
  extId?: string | null;
  user?: IUser | null;
  gateway?: EPaymentGateway;
  status: ESubscriptionStatus;
  period: number;
  price: number;
  currency: ECurrency;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  expiresAt: TDateTime;
  creditsAddAt: TDateTime | null;
  isRecurring: boolean;
  monthlyAmount: number;
}

export interface IStatAd {
  ad?: IAd;
  user?: IUser;
  date?: TDateTime;
  country?: ECountry;
  shows?: number;
  clicks?: number;
}

export interface IUser {
  id: number;
  email: string;
  name: string | null;
  gender?: EGender | null;
  isBlocked?: boolean;
  password?: string | null;
  credits?: number;
  utm?: IUtm | null;
  ip?: string | null;
  country?: ECountry | null;
  avatar: IAsset | null;
  fcmToken?: string | null;
  subscription?: ISubscription | null;
  language: ELanguage;
  service: EService;
  adLastShowAt?: TDateTime | null;
  role?: EUserRole;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  userIdentifier?: string;
  isPaid?: boolean;
}

export interface IAd {
  id: number;
  name?: string;
  countries?: ECountry[];
  gender?: EGender | null;
  languages?: ELanguage[];
  prompt?: string;
  url?: string;
  buttonText: string;
  isActive?: boolean;
  periodBeginAt?: TDateTime | null;
  periodEndAt?: TDateTime | null;
  showAgainAfter?: number;
  delayBeforeShow?: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  buttonUrl: string | null;
}

export interface IMessage {
  id: number;
  sender: ESender;
  text: string | null;
  photo: IAsset | null;
  createdAt: TDateTime;
  ad: IAd | null;
}

export interface IPayment {
  id: number;
  extId: string;
  subscription?: ISubscription;
  type: EPaymentType;
  gateway: EPaymentGateway;
  amount: number;
  details?: Record<string, any>;
  payload?: any | null;
  createdAt?: TDateTime;
}

export interface IPhoto {
  id: number;
  model?: IModel;
  photo: IAsset;
  pose?: EPose | null;
  isPublic?: boolean;
  isDefault?: boolean;
  comment: string | null;
}

export interface ITier {
  id: number;
  name: string;
  countries?: ECountry[];
  currency: ECurrency;
  isEnabled?: boolean;
  isDefault?: boolean;
}

export interface IParam {
  name: string;
  value: any | null;
}

export interface IConversation {
  id: number;
  user?: IUser;
  model: IModel;
  action: EAction;
  lastMessage: IMessage | null;
  counter: number;
  temperature: number;
  level: ELevel;
  isShared?: boolean;
  authorName?: string | null;
  title?: string | null;
  description?: string | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface IGetPhotosRequest {
  model?: number | null;
  asAdmin?: boolean;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IGetModelsListRequest {
  tag?: ETag;
  gender?: EGender;
  style?: EModelStyle;
  preset?: 'public' | 'my' | 'random';
  asAdmin?: boolean;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ICreatePhotoRequest {
  prompt: string;
  pose?: EPose;
  filter?: EFilter;
  count?: number;
  asAdmin?: boolean;
}

export interface IUpdateModelRequest {
  name?: string;
  about?: string;
  prompt?: string;
  welcomeMessage?: string;
  personality?: string;
  occupation?: string;
  hobbies?: string[];
  relationship?: string;
  mainPhotoPrompt?: string | null;
  mainPhotoNegativePrompt?: string | null;
  mainPhotoBackgroundPrompt?: string | null;
  featuresPrompt?: string | null;
  translations?: Record<ELanguage, Record<string, any>>;
  tags?: ETag[];
}

export interface ICreateModelRequest {
  gender: EGender;
  style: EModelStyle;
  age: number;
  ethnicity: EEthnicity;
  eyesColor: EEyesColor;
  hairStyle: EHairStyle;
  hairColor: EHairColor;
  bodyType: EBodyType;
  breastSize?: EBreastSize | null;
  buttSize?: EButtSize | null;
  personality: string;
  occupation: string;
  hobbies: string[];
  relationship: string;
  clothing: string;
  mainPhotoPrompt?: string | null;
  mainPhotoNegativePrompt?: string | null;
  mainPhotoBackgroundPrompt?: string | null;
  featuresPrompt?: string | null;
  tags?: ETag[];
  asAdmin?: boolean;
}

export interface IGetParamsRequest {
  keys: string[];
}

export interface ISetParamsRequest {
  params: Record<string, any>;
}

export interface IGetConversationsRequest {
  model?: number | null;
  style?: EModelStyle | null;
  gender?: EGender | null;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ISendMessageRequest {
  text: string;
}

export interface IUpdateConversationRequest {
  isShared?: boolean;
  authorName?: string | null;
  title?: string | null;
  description?: string | null;
}

export interface IGetMessagesRequest {
  lastMessageId?: number | null;
  limit?: number;
}

export interface IUpdateMessageRequest {
  text?: string | null;
}

export interface IUtm {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_term: string | null;
  esub: string | null;
  pub_id: string | null;
}

export interface IUploadStatus {
  id: string;
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  uploaded?: number;
  progress: number;
  asset: IAsset | null;
  request?: IUploadRequest;
  isReady?: boolean;
}

export interface IUploadRequest {
  fileName: string;
  fileSize: number;
  fileType?: string;
  noS3?: boolean;
}

export interface ICreatePaymentMethodRequest {
  category: EPaymentMethodCategory;
  gateway: EPaymentGateway;
  name: string;
  alias?: string | null;
  icon: string;
  isEnabled?: boolean;
  isBillingInfo?: boolean;
  countries: ECountry[];
  currency?: ECurrency | null;
  minAmount?: number | null;
  maxAmount?: number | null;
}

export interface IUpdatePaymentMethodRequest {
  category?: EPaymentMethodCategory;
  gateway?: EPaymentGateway;
  name?: string;
  alias?: string | null;
  icon?: string;
  isEnabled?: boolean;
  isBillingInfo?: boolean;
  countries?: ECountry[];
  currency?: ECurrency | null;
  minAmount?: number | null;
  maxAmount?: number | null;
}

export interface IGetPaymentMethodsRequest {
  price?: number | null;
  query?: string | null;
  gateway?: EPaymentGateway | null;
  category?: EPaymentMethodCategory | null;
  country?: ECountry | null;
  asAdmin?: boolean;
}

export interface ICommonGetListRequest {
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ISendInterractDataRequest {
  data?: any | null;
}

export interface IUpdateTierRequest {
  name?: string;
  countries?: ECountry[];
  isEnabled?: boolean;
}

export interface IGetPricesRequest {
  type?: EPaymentType | null;
  tier?: number | null;
  product?: number | null;
}

export interface ISubscriptionPricingItem {
  price?: IPrice;
  oldMonthlyPrice: number;
  oldMonthlyPriceFormatted: string;
  discount: string | null;
  monthlyPrice: number;
  monthlyPriceFormatted: string;
  period: number;
  id: number;
  priceFormatted: string;
  currency: ECurrency;
  isRecommended: boolean;
}

export interface IGetProductsRequest {
  type?: EPaymentType | null;
}

export interface IAbstractPricingItem {
  price?: IPrice;
  id: number;
  priceFormatted: string;
  currency: ECurrency;
  isRecommended: boolean;
}

export interface ICreateTierRequest {
  name: string;
  countries?: ECountry[];
  currency: ECurrency;
  isEnabled?: boolean;
}

export interface ICreditsPricingItem {
  price?: IPrice;
  bonus: string | null;
  credits: number;
  id: number;
  priceFormatted: string;
  currency: ECurrency;
  isRecommended: boolean;
}

export interface IUpdatePriceRequest {
  price: number;
}

export interface IGetAdsRequest {
  isActive?: boolean;
  gender?: EGender | null;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IUpdateAdRequest {
  name?: string;
  prompt?: string;
  buttonText?: string;
  url?: string;
  countries?: ECountry[];
  gender?: EGender | null;
  languages?: ELanguage[];
  showAgainAfter?: number;
  delayBeforeShow?: number;
  isActive?: boolean;
  periodBeginAt?: TDateTime | null;
  periodEndAt?: TDateTime | null;
}

export interface ICreateAdRequest {
  name: string;
  prompt: string;
  buttonText: string;
  url: string;
  countries?: ECountry[];
  gender?: EGender | null;
  languages?: ELanguage[];
  showAgainAfter?: number;
  delayBeforeShow?: number;
  isActive?: boolean;
  periodBeginAt?: TDateTime | null;
  periodEndAt?: TDateTime | null;
}

export interface IGetTotalAdStatsRequest {
  ads: number[];
}

export interface IGetSubscriptionsListRequest {
  user?: number;
  status?: ESubscriptionStatus;
  method?: number;
  gateway?: EPaymentGateway;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IGetEdCrazePortalRequest {
  csId: string;
  returnUrl: string;
}

export interface IPurchaseRequest {
  method: string | number;
  paymentDetails?: Record<string, any>;
}

export interface ICreateEdCrazeSubscriptionRequest {
  period: '1' | '3' | '12';
  email: string;
  ref: string;
  returnUrl: string;
  webhookUrl: string;
}

export interface IGetPaymentsListRequest {
  user?: number;
  subscription?: number;
  type?: EPaymentType;
  method?: number;
  gateway?: EPaymentGateway;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ILoginRequest {
  email: string;
  password: string;
  ttl?: number;
}

export interface ILoginWithGoogleRequest {
  accessToken: string;
  utm?: IUtm | null;
}

export interface IUpdateProfileRequest {
  name?: string;
  gender?: EGender;
  password?: string;
  avatarId?: string | null;
  language?: ELanguage;
  country?: ECountry;
  fcmToken?: string | null;
}

export interface IPasswordResetRequest {
  email: string;
}

export interface IRegisterRequest {
  email: string;
  password: string;
  utm?: IUtm | null;
}

export interface ICreateModelTask {
  type: 'model';
  result: number | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface ICreateModelMainPhotoTask {
  modelId?: number;
  type: 'photo';
  result: IPhoto | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface ICreateModelExtraPhotosTask {
  type: 'photo';
  result: IPhoto[] | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface IGetTasksRequest {
  type?: 'photo' | 'model' | null;
  showTerminal?: boolean;
}

export interface ITask {
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  type: string;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export enum EModelStyle {
  Real = 'Real',
  Anime = 'Anime',
}

export enum EEyesColor {
  Brown = 'brown',
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
}

export enum ECurrency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum ELevel {
  Cold = 1,
  Friendly = 2,
  Horny = 3,
  Loving = 4,
}

export enum EHairColor {
  Blonde = 'blonde',
  Brown = 'brown',
  Brunette = 'brunette',
  Black = 'black',
  Ginger = 'ginger',
  Gray = 'gray',
  Redhead = 'redhead',
  Pink = 'pink',
  White = 'white',
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
  Multicolor = 'multicolor',
}

export enum EHairStyle {
  Straight = 'straight',
  Braids = 'braids',
  Bangs = 'bangs',
  Curly = 'curly',
  Bun = 'bun',
  Short = 'short',
  Long = 'long',
  Ponytail = 'ponytail',
  Pigtails = 'pigtails',
  BuzzCut = 'buzz-cut',
  SlickedBack = 'slicked-back',
  Dreadlocks = 'dreadlocks',
  Bald = 'bald',
}

export enum ETaskStatus {
  Pending = 'pending',
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export enum ECountry {
  AU = 'au',
  AT = 'at',
  AZ = 'az',
  AX = 'ax',
  AL = 'al',
  DZ = 'dz',
  VI = 'vi',
  AS = 'as',
  AI = 'ai',
  AO = 'ao',
  AD = 'ad',
  AQ = 'aq',
  AG = 'ag',
  AR = 'ar',
  AM = 'am',
  AW = 'aw',
  AF = 'af',
  BS = 'bs',
  BD = 'bd',
  BB = 'bb',
  BH = 'bh',
  BZ = 'bz',
  BY = 'by',
  BE = 'be',
  BJ = 'bj',
  BM = 'bm',
  BG = 'bg',
  BO = 'bo',
  BQ = 'bq',
  BA = 'ba',
  BW = 'bw',
  BR = 'br',
  IO = 'io',
  VG = 'vg',
  BN = 'bn',
  BF = 'bf',
  BI = 'bi',
  BT = 'bt',
  VU = 'vu',
  VA = 'va',
  GB = 'gb',
  HU = 'hu',
  VE = 've',
  UM = 'um',
  TL = 'tl',
  VN = 'vn',
  GA = 'ga',
  HT = 'ht',
  GY = 'gy',
  GM = 'gm',
  GH = 'gh',
  GP = 'gp',
  GT = 'gt',
  GF = 'gf',
  GN = 'gn',
  GW = 'gw',
  DE = 'de',
  GG = 'gg',
  GI = 'gi',
  HN = 'hn',
  HK = 'hk',
  GD = 'gd',
  GL = 'gl',
  GR = 'gr',
  GE = 'ge',
  GU = 'gu',
  DK = 'dk',
  JE = 'je',
  DJ = 'dj',
  DM = 'dm',
  DO = 'do',
  CD = 'cd',
  EU = 'eu',
  EG = 'eg',
  ZM = 'zm',
  EH = 'eh',
  ZW = 'zw',
  IL = 'il',
  IN = 'in',
  ID = 'id',
  JO = 'jo',
  IQ = 'iq',
  IR = 'ir',
  IE = 'ie',
  IS = 'is',
  ES = 'es',
  IT = 'it',
  YE = 'ye',
  CV = 'cv',
  KZ = 'kz',
  KY = 'ky',
  KH = 'kh',
  CM = 'cm',
  CA = 'ca',
  QA = 'qa',
  KE = 'ke',
  CY = 'cy',
  KG = 'kg',
  KI = 'ki',
  TW = 'tw',
  KP = 'kp',
  CN = 'cn',
  CC = 'cc',
  CO = 'co',
  KM = 'km',
  CR = 'cr',
  CI = 'ci',
  CU = 'cu',
  KW = 'kw',
  CW = 'cw',
  LA = 'la',
  LV = 'lv',
  LS = 'ls',
  LR = 'lr',
  LB = 'lb',
  LY = 'ly',
  LT = 'lt',
  LI = 'li',
  LU = 'lu',
  MU = 'mu',
  MR = 'mr',
  MG = 'mg',
  YT = 'yt',
  MO = 'mo',
  MK = 'mk',
  MW = 'mw',
  MY = 'my',
  ML = 'ml',
  MV = 'mv',
  MT = 'mt',
  MA = 'ma',
  MQ = 'mq',
  MH = 'mh',
  MX = 'mx',
  FM = 'fm',
  MZ = 'mz',
  MD = 'md',
  MC = 'mc',
  MN = 'mn',
  MS = 'ms',
  MM = 'mm',
  NA = 'na',
  NR = 'nr',
  NP = 'np',
  NE = 'ne',
  NG = 'ng',
  NL = 'nl',
  NI = 'ni',
  NU = 'nu',
  NZ = 'nz',
  NC = 'nc',
  NO = 'no',
  AE = 'ae',
  OM = 'om',
  BV = 'bv',
  IM = 'im',
  CK = 'ck',
  NF = 'nf',
  CX = 'cx',
  PN = 'pn',
  SH = 'sh',
  PK = 'pk',
  PW = 'pw',
  PS = 'ps',
  PA = 'pa',
  PG = 'pg',
  PY = 'py',
  PE = 'pe',
  PL = 'pl',
  PT = 'pt',
  PR = 'pr',
  CG = 'cg',
  KR = 'kr',
  RE = 're',
  RU = 'ru',
  RW = 'rw',
  RO = 'ro',
  SV = 'sv',
  WS = 'ws',
  SM = 'sm',
  ST = 'st',
  SA = 'sa',
  SZ = 'sz',
  MP = 'mp',
  SC = 'sc',
  BL = 'bl',
  MF = 'mf',
  PM = 'pm',
  SN = 'sn',
  VC = 'vc',
  KN = 'kn',
  LC = 'lc',
  RS = 'rs',
  SG = 'sg',
  SX = 'sx',
  SY = 'sy',
  SK = 'sk',
  SI = 'si',
  SB = 'sb',
  SO = 'so',
  SD = 'sd',
  SR = 'sr',
  US = 'us',
  SL = 'sl',
  TJ = 'tj',
  TH = 'th',
  TZ = 'tz',
  TC = 'tc',
  TG = 'tg',
  TK = 'tk',
  TO = 'to',
  TT = 'tt',
  TV = 'tv',
  TN = 'tn',
  TM = 'tm',
  TR = 'tr',
  UG = 'ug',
  UZ = 'uz',
  UA = 'ua',
  WF = 'wf',
  UY = 'uy',
  FO = 'fo',
  FJ = 'fj',
  PH = 'ph',
  FI = 'fi',
  FK = 'fk',
  FR = 'fr',
  PF = 'pf',
  TF = 'tf',
  HM = 'hm',
  HR = 'hr',
  CF = 'cf',
  TD = 'td',
  ME = 'me',
  CZ = 'cz',
  CL = 'cl',
  CH = 'ch',
  SE = 'se',
  SJ = 'sj',
  LK = 'lk',
  EC = 'ec',
  GQ = 'gq',
  ER = 'er',
  EE = 'ee',
  ET = 'et',
  ZA = 'za',
  GS = 'gs',
  SS = 'ss',
  JM = 'jm',
  JP = 'jp',
  WW = 'ww',
}

export enum EAction {
  None = 'none',
  Waiting = 'waiting',
  Typing = 'typing',
  UploadPhoto = 'upload_photo',
}

export enum EService {
  CherryX = 'cherryx',
  Passioz = 'passioz',
}

export enum ECardType {
  VISA = 'visa',
  MasterCard = 'mastercard',
  Maestro = 'maestro',
  Discover = 'discover',
  Diners = 'diners',
  UnionPay = 'unionpay',
  RuPay = 'rupay',
  AmericanExpress = 'americanexpress',
  JCB = 'jcb',
}

export enum EUserRole {
  User = 'user',
  Admin = 'admin',
  AdManager = 'ad_manager',
}

export enum EPaymentType {
  Subscription = 'sub',
  Credits = 'cred',
}

export enum EBreastSize {
  Flat = 'flat',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Huge = 'huge',
}

export enum EBodyType {
  Petite = 'petite',
  Slim = 'slim',
  Athletic = 'athletic',
  Voluptuous = 'voluptuous',
  Curvy = 'curvy',
  Muscular = 'muscular',
  Wide = 'wide',
}

export enum EPose {
  Default = 'Default',
  POVMissionary = 'POV Missionary',
  POVBlowjob = 'POV Blowjob',
  POVDoggystyle = 'POV Doggystyle',
  CuminMouth = 'Cum in Mouth',
  AfterSex = 'After Sex',
  Handjob = 'Handjob',
  CarryingSex = 'Carrying Sex',
  FlashingBoobs = 'Flashing Boobs',
  BreastSqueeze = 'Breast Squeeze',
  FrontViewCowgirl = 'Front View Cowgirl',
  ButtJob = 'ButtJob',
  SideViewBlowjob = 'Side View Blowjob',
  StickoutTongue = 'Stick out Tongue',
  POVSpitroast = 'POV Spitroast',
  JustBeforeSex = 'Just Before Sex',
  POVThreesomeBlowJob = 'POV Threesome BlowJob',
  GrabAss = 'Grab Ass',
  MatingPress = 'Mating Press',
  POVReverseCowgirl = 'POV Reverse Cowgirl',
  ThighSex = 'Thigh Sex',
  POVAnal = 'POV Anal',
  Piledrive = 'Piledrive',
  VaginaSpread = 'Vagina Spread',
  ImminentSex = 'Imminent Sex',
  FemaleMasturbation = 'Female Masturbation',
  JackoPose = 'Jacko Pose',
  SidewayAss = 'Sideway Ass',
  Titjob = 'Titjob',
  TitjobReal = 'Titjob Real',
  TitjobAnime = 'Titjob Anime',
  SpreadAss = 'Spread Ass',
  Bukkake = 'Bukkake',
  Spooning = 'Spooning',
  AmazonPosition = 'Amazon Position',
  OnOffClothing = 'On/Off Clothing',
  ShirtPull = 'Shirt Pull',
  CheekBulgeBlowjob = 'Cheek Bulge Blowjob',
  SoloBreastGrab = 'Solo Breast Grab',
  FromBelow = 'From Below',
  RestingOnStomach = 'Resting On Stomach',
  BlowjobUnderDesk = 'Blowjob Under Desk',
  Penis = 'Penis',
  BreastsPressedAgainstGlass = 'Breasts Pressed Against Glass',
  ReverseDeepthroat = 'Reverse Deepthroat',
  Orgy = 'Orgy',
  CumonAss = 'Cum on Ass',
  Cumshot = 'Cumshot',
  AssOnGlass = 'Ass On Glass',
  MultipleHands = 'Multiple Hands',
  Facesitting = 'Facesitting',
  OnOff = 'On Off',
  SideBlowjob = 'Side Blowjob',
  Kneeling = 'Kneeling',
  POVBreastGrab = 'POV Breast Grab',
  SurroundedbyPenises = 'Surrounded by Penises',
  OrgasmFace = 'Orgasm Face',
  FlashingInPublic = 'Flashing In Public',
  Mooning = 'Mooning',
  WetTshirt = 'Wet Tshirt',
  LesbianOral = 'Lesbian Oral',
  LesbianFingering = 'Lesbian Fingering',
  LesbianScissoring = 'Lesbian Scissoring',
  GayCowboyAnime = 'Gay Cowboy Anime',
  GayCowboyReal = 'Gay Cowboy Real',
  GayGrabbing = 'Gay Grabbing',
  BDSMSuspension = 'BDSM Suspension',
  BDSMTiedUp = 'BDSM Tied Up',
  BDSMTape = 'BDSM Tape',
  BDSMBallgag = 'BDSM Ballgag',
  BDSMLeash = 'BDSM Leash',
  Downblouse = 'Downblouse',
  Sitting = 'Sitting',
  Vagina = 'Vagina',
  Showering = 'Showering',
  CumBath = 'Cum Bath',
  Cuddling = 'Cuddling',
  LesbianCuddling = 'Lesbian Cuddling',
  GayCuddling = 'Gay Cuddling',
  POVCowgirl = 'POV Cowgirl',
  Feet = 'Feet',
  MultipleMooning = 'Multiple Mooning',
  Giant = 'Giant',
  Upset = 'Upset',
  Disgusted = 'Disgusted',
  Scared = 'Scared',
  Winking = 'Winking',
  Angry = 'Angry',
  Smiling = 'Smiling',
  Laughing = 'Laughing',
  Ouch = 'Ouch',
  Shocked = 'Shocked',
  SideBlowjobReal = 'Side Blowjob Real',
  SuckingNipple = 'Sucking Nipple',
  FingerSucking = 'Finger Sucking',
  ShowingoffAss = 'Showing off Ass',
  WindLiftNipSlip = 'Wind Lift/ Nip Slip',
  ReverseBlowjob = 'Reverse Blowjob',
  LyingDownFeet = 'Lying Down Feet',
  Footjob = 'Footjob',
  Dildo = 'Dildo',
  HandbraHoldingBoobs = 'Handbra/Holding Boobs',
  MilkingMachine = 'Milking Machine',
  PantiesOff = 'Panties Off',
  DoubleHandjob = 'Double Handjob',
  Undressing = 'Undressing',
  BubbleBath = 'Bubble Bath',
  CaughtNakedEmbarrassed = 'Caught Naked Embarrassed',
  Gloryhole = 'Gloryhole',
  XrayGlasses = 'Xray Glasses',
  ManGrabbingBoobs = 'Man Grabbing Boobs',
  GloryWall = 'Glory Wall',
  WindLift = 'Wind Lift',
  POVDeepthroat = 'POV Deepthroat',
  POVStrangling = 'POV Strangling',
  LickingDick = 'Licking Dick',
}

export enum EPaymentGateway {
  CryptoCloud = 'cryptocloud',
  Payssion = 'payssion',
  PayCly = 'paycly',
  APay = 'apay',
  Stripe = 'stripe',
  PayPal = 'paypal',
  UpGate = 'upgate',
}

export enum ETag {
  Milf = 'milf',
  Hentai = 'hentai',
  Latina = 'latina',
  Asian = 'asian',
  Ebony = 'ebony',
  BigAss = 'big-ass',
  BigTits = 'big-tits',
  Petite = 'petite',
  Pregnant = 'pregnant',
  Furry = 'furry',
  Slim = 'slim',
  Fat = 'fat',
  Muscular = 'muscular',
  ColoredHair = 'colored-hair',
  Arab = 'arab',
  Dominant = 'dominant',
  Submissive = 'submissive',
  Elf = 'elf',
  BoundWithRopes = 'bound-with-ropes',
  BDSM = 'bdsm',
  Athletes = 'athletes',
  Students = 'students',
}

export enum EButtSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Skinny = 'skinny',
  Athletic = 'athletic',
}

export enum ETransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export enum EPaymentMethodCategory {
  Card = 'card',
  Crypto = 'crypto',
  Other = 'other',
}

export enum ESubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  Expired = 'expired',
  Cancel = 'cancel',
}

export enum ELanguage {
  English = 'en',
  Russian = 'ru',
  Spanish = 'es',
  French = 'fr',
  German = 'de',
  Japanese = 'ja',
  Italian = 'it',
  Korean = 'ko',
  Portuguese = 'pt',
  Indonesian = 'id',
  Vietnamese = 'vi',
  Thai = 'th',
  Hindi = 'hi',
  Malay = 'ms',
}

export enum EGender {
  Male = 'male',
  Female = 'female',
}

export enum ESender {
  User = 'user',
  Model = 'model',
}

export enum EDictionaryCollection {
  Clothing = 'clothing',
  Hobbies = 'hobbies',
  Occupations = 'occupations',
  Personalities = 'personalities',
  Relationships = 'relationships',
}

export enum EEthnicity {
  Caucasian = 'caucasian',
  Latin = 'latin',
  Asian = 'asian',
  Arab = 'arab',
  Afro = 'afro',
  Indian = 'indian',
}

export enum ESortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EFilter {
  Default = 'Default',
  Cyberpunk = 'Cyberpunk',
  VHS = 'VHS',
  Studio = 'Studio',
  Polaroid = 'Polaroid',
  Vintage = 'Vintage',
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  ModelFull = 'model:full',
  ModelWelcome = 'model:welcome',
  ModelAbout = 'model:about',
  ModelOwner = 'model:owner',
  ModelLanguages = 'model:languages',
  ModelTranslations = 'model:translations',
  PriceProduct = 'price:product',
  PriceFull = 'price:full',
  PriceTier = 'price:tier',
  AssetDuration = 'asset:duration',
  AssetFull = 'asset:full',
  AssetResolution = 'asset:resolution',
  AssetS3 = 'asset:s3',
  Extra = 'extra',
  PaymentMethodFull = 'payment-method:full',
  TransactionUser = 'transaction:user',
  TransactionDetails = 'transaction:details',
  SubscriptionFull = 'subscription:full',
  SubscriptionUser = 'subscription:user',
  UserSubscription = 'user:subscription',
  UserGender = 'user:gender',
  UserFull = 'user:full',
  UserUtm = 'user:utm',
  UserIp = 'user:ip',
  UserCountry = 'user:country',
  UserRole = 'user:role',
  AdBrief = 'ad:brief',
  AdFull = 'ad:full',
  PaymentSubscription = 'payment:subscription',
  PaymentDetails = 'payment:details',
  PaymentPayload = 'payment:payload',
  PhotoModel = 'photo:model',
  PhotoFull = 'photo:full',
  TierFull = 'tier:full',
  ConversationUser = 'conversation:user',
  ConversationShared = 'conversation:shared',
}

class Models {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (request: IGetModelsListRequest, fields?: EFieldGroup[]): Promise<IPagedData<IModel>> =>
    this.api.get(`/models`, request, fields);

  create = (request: ICreateModelRequest, fields?: EFieldGroup[]): Promise<ICreateModelTask> =>
    this.api.post(`/models/create`, request, fields);

  getModel = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.get(`/models/${model}`, {}, fields);

  updateModel = (model: TIdentifier, request: IUpdateModelRequest, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.patch(`/models/${model}`, request, fields);

  createMeta = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.post(`/models/${model}/photos/meta`, {}, fields);

  addTranslation = (model: TIdentifier, language: TIdentifier, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.post(`/models/${model}/language/${language}`, {}, fields);

  createMainPhoto = (model: TIdentifier, fields?: EFieldGroup[]): Promise<ICreateModelMainPhotoTask> =>
    this.api.post(`/models/${model}/photos/main`, {}, fields);

  getMainPhotos = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IPhoto[]> =>
    this.api.get(`/models/${model}/photos/main`, {}, fields);

  createExtraPhoto = (
    model: TIdentifier,
    request: ICreatePhotoRequest,
    fields?: EFieldGroup[]
  ): Promise<ICreateModelExtraPhotosTask> => this.api.post(`/models/${model}/photos/extra`, request, fields);

  getPhotos = (
    model: TIdentifier,
    request: ICommonGetListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPhoto>> => this.api.get(`/models/${model}/photos`, request, fields);

  startConversation = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IConversation> =>
    this.api.post(`/models/${model}/conversation`, {}, fields);

  deleteModel = (model: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/models/${model}`, {}, fields);
}

class Interract {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  send = (id: TIdentifier, request: ISendInterractDataRequest, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.post(`/interract/${id}`, request, fields);
}

class Pricing {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getSubscriptionPricing = (fields?: EFieldGroup[]): Promise<ISubscriptionPricingItem[]> =>
    this.api.get(`/pricing/subscription`, {}, fields);

  getCreditsPricing = (fields?: EFieldGroup[]): Promise<ICreditsPricingItem[]> =>
    this.api.get(`/pricing/credits`, {}, fields);

  getTiers = (fields?: EFieldGroup[]): Promise<ITier[]> => this.api.get(`/pricing/tiers`, {}, fields);

  createTier = (request: ICreateTierRequest, fields?: EFieldGroup[]): Promise<ITier> =>
    this.api.post(`/pricing/tiers`, request, fields);

  updateTier = (tier: TIdentifier, request: IUpdateTierRequest, fields?: EFieldGroup[]): Promise<ITier> =>
    this.api.patch(`/pricing/tiers/${tier}`, request, fields);

  deleteTier = (tier: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/pricing/tiers/${tier}`, {}, fields);

  getTier = (tier: TIdentifier, fields?: EFieldGroup[]): Promise<ITier> =>
    this.api.get(`/pricing/tiers/${tier}`, {}, fields);

  getPrices = (request: IGetPricesRequest, fields?: EFieldGroup[]): Promise<IPrice[]> =>
    this.api.get(`/pricing/prices`, request, fields);

  updatePrice = (price: TIdentifier, request: IUpdatePriceRequest, fields?: EFieldGroup[]): Promise<IPrice> =>
    this.api.patch(`/pricing/prices/${price}`, request, fields);

  getProducts = (request: IGetProductsRequest, fields?: EFieldGroup[]): Promise<IProduct[]> =>
    this.api.get(`/pricing/products`, request, fields);
}

class Assets {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAsset = (asset: TIdentifier, fields?: EFieldGroup[]): Promise<IAsset> =>
    this.api.get(`/assets/${asset}`, {}, fields);

  uploadForm = (form: FormData): Promise<IAsset> => this.api.post(`/assets/upload/form`, form, [EFieldGroup.AssetFull]);

  partial = (request: IUploadRequest, fields?: EFieldGroup[]): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/partial`, request, fields);

  chunk = (form: FormData): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/chunk`, form, [EFieldGroup.AssetFull]);
}

class Users {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getUsersList = (request: ICommonGetListRequest, fields?: EFieldGroup[]): Promise<IPagedData<IUser>> =>
    this.api.get(`/users`, request, fields);

  loginAs = (user: TIdentifier, fields?: EFieldGroup[]): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/${user}/auth`, {}, fields);

  login = (request: ILoginRequest, fields?: EFieldGroup[]): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/login`, request, fields);

  loginWithGoogle = (
    request: ILoginWithGoogleRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> => this.api.post(`/users/login/google`, request, fields);

  register = (request: IRegisterRequest, fields?: EFieldGroup[]): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/register`, request, fields);

  resetPassword = (request: IPasswordResetRequest, fields?: EFieldGroup[]): Promise<true> =>
    this.api.post(`/users/password`, request, fields);

  getMe = (fields?: EFieldGroup[]): Promise<IUser> => this.api.get(`/users/me`, {}, fields);

  updateProfile = (request: IUpdateProfileRequest, fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.patch(`/users/me`, request, fields);

  getUserById = (user: TIdentifier, fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/${user}`, {}, fields);

  deleteAccount = (fields?: EFieldGroup[]): Promise<boolean> => this.api.delete(`/users/profile`, {}, fields);
}

class SavedPaymentMethods {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getSavedMethods = (fields?: EFieldGroup[]): Promise<ISavedPaymentMethod[]> =>
    this.api.get(`/billing/methods`, {}, fields);

  setDefault = (spm: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.post(`/billing/methods/${spm}/default`, {}, fields);

  delete = (spm: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/billing/methods/${spm}`, {}, fields);
}

class Tasks {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (request: IGetTasksRequest, fields?: EFieldGroup[]): Promise<ITask[]> =>
    this.api.get(`/tasks`, request, fields);

  getTask = <T>(id: TIdentifier, fields?: EFieldGroup[]): Promise<T> => this.api.get(`/tasks/${id}`, {}, fields);
}

class Params {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getParams = (request: IGetParamsRequest, fields?: EFieldGroup[]): Promise<Record<string, any>> =>
    this.api.get(`/params`, request, fields);

  setParams = (request: ISetParamsRequest, fields?: EFieldGroup[]): Promise<true> =>
    this.api.post(`/params`, request, fields);
}

class PaymentMethods {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getPaymentMethods = (
    request: IGetPaymentMethodsRequest,
    fields?: EFieldGroup[]
  ): Promise<{ country: ECountry; methods: IPaymentMethod[] }> => this.api.get(`/payments/methods`, request, fields);

  createPaymentMethod = (request: ICreatePaymentMethodRequest, fields?: EFieldGroup[]): Promise<IPaymentMethod> =>
    this.api.post(`/payments/methods`, request, fields);

  updatePaymentMethod = (
    method: TIdentifier,
    request: IUpdatePaymentMethodRequest,
    fields?: EFieldGroup[]
  ): Promise<IPaymentMethod> => this.api.patch(`/payments/methods/${method}`, request, fields);

  deletePaymentMethod = (method: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/payments/methods/${method}`, {}, fields);
}

class Gallery {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getModels = (fields?: EFieldGroup[]): Promise<IModel[]> => this.api.get(`/gallery/models`, {}, fields);

  getPhotos = (request: IGetPhotosRequest, fields?: EFieldGroup[]): Promise<IPagedData<IPhoto>> =>
    this.api.get(`/gallery`, request, fields);

  setDefaultPhoto = (photo: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.patch(`/gallery/photos/${photo}/default`, {}, fields);

  deletePhoto = (photo: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/gallery/photos/${photo}`, {}, fields);
}

class Ad {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getTotalAdStats = (
    request: IGetTotalAdStatsRequest,
    fields?: EFieldGroup[]
  ): Promise<Record<number, { shows: number; clicks: number }>> => this.api.get(`/a/stats/total`, request, fields);

  getAds = (request: IGetAdsRequest, fields?: EFieldGroup[]): Promise<IPagedData<IAd>> =>
    this.api.get(`/a`, request, fields);

  getAd = (ad: TIdentifier, fields?: EFieldGroup[]): Promise<IAd> => this.api.get(`/a/${ad}`, {}, fields);

  createAd = (request: ICreateAdRequest, fields?: EFieldGroup[]): Promise<IAd> => this.api.post(`/a`, request, fields);

  updateAd = (ad: TIdentifier, request: IUpdateAdRequest, fields?: EFieldGroup[]): Promise<IAd> =>
    this.api.patch(`/a/${ad}`, request, fields);

  deleteAd = (ad: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> => this.api.delete(`/a/${ad}`, {}, fields);
}

class Subscriptions {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getCurrentSubscription = (fields?: EFieldGroup[]): Promise<ISubscription | null> =>
    this.api.get(`/subscriptions/current`, {}, fields);

  unsubscribe = (fields?: EFieldGroup[]): Promise<boolean | string> =>
    this.api.delete(`/subscriptions/current`, {}, fields);

  purchase = (
    price: TIdentifier,
    request: IPurchaseRequest,
    fields?: EFieldGroup[]
  ): Promise<{ transaction: ITransaction; redirect: string }> =>
    this.api.post(`/subscriptions/purchase/${price}`, request, fields);

  getStripePortalUrl = (fields?: EFieldGroup[]): Promise<string> =>
    this.api.get(`/subscriptions/stripe-portal`, {}, fields);

  getList = (request: IGetPaymentsListRequest, fields?: EFieldGroup[]): Promise<IPagedData<ISubscription>> =>
    this.api.get(`/subscriptions`, request, fields);
}

class Dictionary {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getFull = (fields?: EFieldGroup[]): Promise<Record<EGender, Record<EDictionaryCollection, string[]>>> =>
    this.api.get(`/dictionary`, {}, fields);
}

class Conversations {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getSharedList = (request: IGetConversationsRequest, fields?: EFieldGroup[]): Promise<IPagedData<IConversation>> =>
    this.api.get(`/conversations/shared`, request, fields);

  getList = (fields?: EFieldGroup[]): Promise<IConversation[]> => this.api.get(`/conversations`, {}, fields);

  getConversation = (conversation: TIdentifier, fields?: EFieldGroup[]): Promise<IConversation> =>
    this.api.get(`/conversations/${conversation}`, {}, fields);

  getMessages = (
    conversation: TIdentifier,
    request: IGetMessagesRequest,
    fields?: EFieldGroup[]
  ): Promise<IMessage[]> => this.api.get(`/conversations/${conversation}/messages`, request, fields);

  cloneShared = (conversation: TIdentifier, fields?: EFieldGroup[]): Promise<IConversation> =>
    this.api.post(`/conversations/${conversation}/clone`, {}, fields);

  warm = (conversation: TIdentifier, fields?: EFieldGroup[]): Promise<IConversation> =>
    this.api.post(`/conversations/${conversation}/warm`, {}, fields);

  sendMessage = (conversation: TIdentifier, request: ISendMessageRequest, fields?: EFieldGroup[]): Promise<IMessage> =>
    this.api.post(`/conversations/${conversation}/messages`, request, fields);

  resetConversation = (conversation: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/conversations/${conversation}/messages`, {}, fields);

  deleteConversation = (conversation: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/conversations/${conversation}`, {}, fields);

  updateConversation = (
    conversation: TIdentifier,
    request: IUpdateConversationRequest,
    fields?: EFieldGroup[]
  ): Promise<IConversation> => this.api.patch(`/conversations/${conversation}`, request, fields);

  deleteMessage = (message: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/conversations/messages/${message}`, {}, fields);

  updateMessage = (message: TIdentifier, request: IUpdateMessageRequest, fields?: EFieldGroup[]): Promise<IMessage> =>
    this.api.patch(`/conversations/messages/${message}`, request, fields);
}
