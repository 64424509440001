import React, {FC, HTMLProps} from 'react';
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import {ESubscriptionStatus} from "../modules/rest";
import {openModalNoMoney} from "../modals";
import Button from "./Button";
import diamond from "../assets/icons/diamond.svg";
import {isSub} from "../modules/utils";

interface Props extends HTMLProps<any> {
  isNsfw?: boolean;
  enableClick?: boolean;
  imageId?: string;
  handleAuth?: () => void;
  onPremiumNavigate: () => void;
}

const BlurComponent: FC<Props> = observer(({
                                             enableClick,
                                             className,
                                             isNsfw,
                                             onClick,
                                             imageId,
                                             handleAuth,
                                             onPremiumNavigate,
                                             ...props
                                           }) => {
  const isPrem = isSub(AppStore.user?.subscription?.status);

  isNsfw = isNsfw && !isPrem;

  const handleClick = (e: any) => {
    if (onClick && (!isNsfw || enableClick)) onClick(e);
  }

  const handleContainerClick = () => {
    if (!AppStore.user?.id && handleAuth && isNsfw) {
      handleAuth();
    } else if (isNsfw) {
      openModalNoMoney('chat').then((path) => {
        if (path) onPremiumNavigate();
      });
    }
  }

  return (
    <div className={isNsfw ? ' blur-container' : ''} onClick={handleContainerClick}>
      <div className={`${className || ''}${isNsfw ? ' blur-content' : ''}`} onClick={handleClick} {...props}>
        {props.children}
      </div>
      {isNsfw &&
        <Button
          size='sm'
          className='blur-btn'
          title={'BECOME_PREMIUM'}
          icon={diamond}
        />
      }
    </div>
  );
})

export default BlurComponent;