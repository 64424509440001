import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import AppStore from "../../store/AppStore";
import {formatDate} from "../../modules/utils";
import diamond_svg from "../../assets/icons/diamond.svg";
import PremiumCards from "../../pages/SettingsPage/components/PremiumCards";
import {confirmDialog} from "../ConfirmModal";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import Loadable from "../../components/Loadable";


interface ConfirmModalProps {
  resolve(unsub?: boolean): void;
}

const PaymentManageModal: FC<ConfirmModalProps> = observer(({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resolve();
  }

  const handleUnsubscribe = async () => {
    const confirmed = await confirmDialog(t('UNSUB_CONFIRM_TEXT'), {
      title: t('UNSUB')!,
      cancelText: 'NO',
    });
    if (confirmed) {
      try {
        setLoading(true)
        await API.Subscriptions.unsubscribe();
        await AppStore.getUser();
        toast.success(t('UNSUB_SUCCESS'))
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false)
      }
    }
  }

  const subscription = AppStore.user?.subscription;

  return (
    <div className='modal-container big sub-manage-modal'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='text-center px-5'>{t('MANAGE_SUB')}</h4>
      <Loadable loading={loading} className="sub-manage-modal-info">
        <div className='d-flex px-3 py-12 align-items-center'>
          <div className='flex-1 pe-2 text-bold d-flex align-items-center'>
            <span>{t('CURRENT_PLAN')}</span>
            <div className='settings-sub'>
              <ReactSVG src={diamond_svg} className='react-icon'/>
              <span>{t('PREMIUM')}</span>
            </div>
          </div>
          {subscription?.isRecurring &&
            <div className='text-bold-14 text-error cursor-pointer' onClick={handleUnsubscribe}>{t('UNSUB')}</div>
          }
        </div>
        <div className="sub-manage-about">
          <div className='flex-1'>
            <div className='text-dark-14'>{t('SUBSCRIPTION')}</div>
            <div className='text-bold-14'>{t(subscription?.status?.toUpperCase() || '') || '-'}</div>
          </div>
          <div className='flex-1'>
            <div className='text-dark-14'>{t('COST')}</div>
            <div className='text-bold-14'>{subscription?.monthlyAmount} {subscription?.currency} / <span
              className='text-lowercase'>{t('MONTH')}</span></div>
          </div>
          <div className='flex-1'>
            <div className='text-dark-14'>{t(subscription?.isRecurring ? 'NEXT_PAYMENT' : 'EXPIRED_DATE')}</div>
            <div className='text-bold-14'>{formatDate(subscription?.expiresAt)}</div>
          </div>
        </div>
      </Loadable>
      <PremiumCards/>

    </div>
  );
});

const openPaymentManageModal = (): Promise<boolean> => {
  return asyncModal(PaymentManageModal, {}, {
    showCloseIcon: false,
    center: true,
    // classNames: {modalContainer: 'bottom-modal'},
    // animationDuration: 0,
  });
};

export {openPaymentManageModal};
