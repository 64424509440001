import React, {FC, useEffect, useLayoutEffect,} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import AppStore from "../../store/AppStore";
import {API} from "../../modules/api";
import {
  EFieldGroup,
  ESortOrder, ICreateModelExtraPhotosTask,
  IGetPhotosRequest,
  IPagedData,
  IPhoto,
} from "../../modules/rest";
import {useNavigate, useSearchParams} from "react-router-dom";
import GalleryNav from "./components/GalleryNav";
import GalleryItem from "./components/GalleryItem";
import {runInAction} from "mobx";
import Pagination from "../../components/Pagination";
import Loadable, {Spinner} from "../../components/Loadable";
import {HeaderSecondary} from "../../containers/Header";
import EmptyList from "../../components/EmptyList";
import NewAIProcess from "../MyAIPage/components/NewAIProcess";
import {useTask} from "../../hooks/useTask";
import {confirmDialog} from "../../modals";
import trash from "../../assets/icons/trash.svg";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
}

interface State {
  ready?: boolean;
  loading?: boolean;
  request: IGetPhotosRequest;
  pager?: IPagedData<IPhoto>;
}

const GalleryPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const queryModelId = Number(searchParams.get('modelId'));
  const queryPage = Number(searchParams.get('page'));

  const st = useLocalObservable<State>(() => ({
    loading: false,
    models: [],
    request: {
      query: '',
      limit: 12,
      order: ESortOrder.DESC,
    },
  }));

  useEffect(() => {
    if (AppStore.ready) {
      if (!AppStore.user?.id) return navigate('/', {replace: true});
      runInAction(() => {
        st.request = {...st.request, page:  queryPage || 1, model:  queryModelId || null,}
      })
      fetch(AppStore.ready);
    }
  }, [AppStore.ready, queryModelId, queryPage]);


  const fetch = async (startLoading?: boolean) => {
    runInAction(() => {
      st.loading = startLoading || false;
    })
    try {
      const res = await API.Gallery.getPhotos(st.request, [EFieldGroup.PhotoModel]);
      runInAction(() => {
        st.pager = res;
      })
    } catch (e) {

    } finally {
      runInAction(() => {
        st.ready = true;
        st.loading = false;
      })
    }
  }

  const handleRemove =  (id: number) => async (e: any) => {
    e.stopPropagation()
    const confirmed = await confirmDialog(t('Confirm_message_delete_picture'), {
      title: t('DELETE_PICTURE')!,
      icon: trash,
      confirmText: 'DELETE',
    })
    if (confirmed) {
      try {
        await API.Gallery.deletePhoto(id);
        fetch()
      } catch (e: any) {
        toast.error(e);
      }
    }
  }

  const [task, generatingAILoading] = useTask<ICreateModelExtraPhotosTask>({
    fetch,
    type: "photo",
  });

  useEffect(() => {
    if (task?.result?.length) fetch();
  }, [task?.result?.length]);


  if (!st.ready) return (
    <div className='container d-flex align-items-center flex-column'>
      <h2 className='page-title mb-4'>{t('GALLERY')}</h2>
      <Spinner loading/>
    </div>
  )

  return (
    <>
      <HeaderSecondary title='GALLERY' onClick={() => AppStore.toggleVisibleAside()} />
      <div className='container gallery-page'>
        <h2 className='page-title mb-4'>{t('GALLERY')}</h2>
        <GalleryNav activeModelId={st.request.model} onChange={(req) => runInAction(() => {
          st.request = {...st.request, ...req};
        })}/>
        <div className='position-relative d-flex justify-content-center flex-1'>
          <Spinner loading={st.loading} absolute style={{top: -14}}/>
          <Loadable  showSpinner={false} className="row g-3 g-lg-4 gallery-list">
            {generatingAILoading
              ?
              <div className='col-6 col-sm-4 col-lg-3'>
                <NewAIProcess finished={generatingAILoading === "finished"} title={'CREATE_NEW_IMAGE'}/>
              </div>
              : null
            }
            {st.pager?.data?.length
              ?
              st.pager.data.map(item => (
                <GalleryItem
                  key={item.id}
                  item={item}
                  onRemove={handleRemove(item.id)}
                />
              ))
              :
              <EmptyList/>
            }
          </Loadable>
        </div>
        <Pagination
          onPageChange={(page) =>
            runInAction(() => {
              searchParams.set('page', String(page));
              setSearchParams(searchParams);
              st.request.page = page;
              fetch(true);
              //@ts-ignore
              window.scrollTo({top: 0, behavior: 'smooth'})
            })
          }
          pager={st.pager}
        />
      </div>
    </>
  );
})

export default GalleryPage;