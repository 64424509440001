import React, {FC, useEffect, useState} from 'react';
import {useNavigate } from "react-router-dom";
import {observer} from "mobx-react";
import {EFieldGroup, ICreateModelTask, IModel} from "../../modules/rest";
import {API} from "../../modules/api";
import AppStore from "../../store/AppStore";
import ActiveElement from "../../components/ActiveElement";
import ModelCard from "../../components/ModelCard";
import CreateNewAI from "./components/CreateNewAI";
import NewAIProcess from "./components/NewAIProcess";
import {toast} from "react-toastify";
import {HeaderSecondary} from "../../containers/Header";
import Button from "../../components/Button";
import plus_svg from "../../assets/icons/plus.svg";
import {Spinner} from "../../components/Loadable";
import {useTask} from "../../hooks/useTask";
import {useTranslation} from "react-i18next";

interface Props {
}

const MyAIPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<IModel[]>([]);

  useEffect(() => {
    if (AppStore.ready) {
      if (AppStore.user?.id) {
        fetch();
      } else {
        setReady(true);
      }
    }
  }, [AppStore.ready]);

  const fetch = async () => {
    try {
      setLoading(true);
      const res = await API.Models.getList({preset: 'my', limit: 1000}, [EFieldGroup.ModelFull]);
      setModels(res.data)
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  }

  const [task, generatingAILoading] = useTask<ICreateModelTask>({
    fetch,
    type: "model"
  });

  // useEffect(() => {
  //   if(task?.status) navigate('/my-ai');
  // }, [task?.status]);


  const handleAnswer = async (id: number) => {
    try {
      const res = await API.Models.startConversation(id);
      navigate(`/chat/${res.id}`);
    } catch (e) {

    }
  }

  return (
    <>
      <HeaderSecondary title='MY_AI' onClick={() => AppStore.toggleVisibleAside()}>
        <Button
          icon={plus_svg}
          btnType='secondary'
          onClick={() => navigate('/create-ai')}
        />
      </HeaderSecondary>
      {ready
        ?
        <div className='container px-4 pb-3 pb-md-4'>
          <div className='d-flex position-relative justify-content-center'>
            <h2 className='page-title'>{t('MY_AI')}</h2>
            {/*<Spinner loading={loading} absolute style={{bottom: 12}}/>*/}
          </div>
          <div className="row g-3 g-lg-4 justify-content-center">
            <div className='col-6 col-sm-4 col-lg-3'>
              {generatingAILoading ? <NewAIProcess finished={generatingAILoading === "finished"}/> : <CreateNewAI/>}
            </div>
            {models.map((model) => (
              <div className='col-6 col-sm-4 col-lg-3' key={model.id}>
                <ActiveElement>
                  <ModelCard
                    onClick={() => handleAnswer(model.id)}
                    model={model}
                    category={model.style}
                    imageSize={900}
                    chatBtn
                  />
                </ActiveElement>
              </div>
            ))}
          </div>
        </div>
        :
        <div className='justify-content-center d-flex'>
          <Spinner loading/>
        </div>
      }
    </>
  );
})

export default MyAIPage;