import React, {FC} from 'react';
import {IPhoto} from "../../../modules/rest";
import {thumb, thumbAvatar} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import expand from "../../../assets/icons/expand.svg";
import trash from "../../../assets/icons/trash.svg";
import Avatar from "../../../components/Avatar";
import {openGalleryModal} from "../../../modals";
import ImageComponent from "../../../components/ImageComponent";
import BlurComponent from "../../../components/BlurComponent";
import {useNavigate} from "react-router-dom";

interface Props {
  item: IPhoto;
  onRemove: (e: any) => void;
}

const GalleryItem: FC<Props> = ({item, onRemove}) => {
  const navigate = useNavigate()

  const handleOpenGallery = (e: any) => {
    openGalleryModal({image: item.photo, removable: true}).then(remove => {
      if (remove) onRemove(e);
    })
  }

  return (
    <div className='col-6 col-sm-4 col-lg-3'>
      <BlurComponent
        onPremiumNavigate={() => navigate('/premium/subscription')}
        isNsfw={item.photo.isNsfw}
        className='gallery-item btn-expand-container btn-trash-container'
        onClick={handleOpenGallery}
      >
        <ImageComponent
          src={thumb(item.photo.id, 800)}
          preloadSrc={thumb(item.photo.id, 50)}
          alt="img"
          className='gallery-item-photo'
        />
        <ReactSVG src={expand} className='react-icon btn-expand'/>
        <ReactSVG src={trash} className='react-icon btn-trash' onClick={onRemove}/>
        <div className="gallery-item-model">
          <Avatar image={thumbAvatar(item.model?.mainPhotoImage?.id, 128)} size='xs'/>
          <div className='gallery-item-name'>
            {item.model?.name}
          </div>
        </div>
      </BlurComponent>
    </div>
  );
}

export default GalleryItem;