import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {EFieldGroup, IModel} from "../../modules/rest";
import ModelProfile from "./components/ModelProfile";
import arrow_left from "../../assets/icons/arrow_left.svg";
import Button from "../../components/Button";
import {ChatSettings} from "../ChatPage";
import {API} from "../../modules/api";

interface Props {
}

const ModelProfilePage: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [model, setModel] = useState<IModel>()

  useEffect(() => {
    if (location.state?.model) {
      setModel(JSON.parse(location.state?.model));
    } else if (params?.id) fetch();
    else {
      navigate('/');
    }
  }, []);

  const fetch = async () => {
    try {
      const res = await API.Models.getModel(params?.id!, [EFieldGroup.ModelFull]);
      setModel(res);
    } catch (e) {
      navigate('/');
    }
  }

  return (
    <div className='model-profile-page'>
      <div className="model-profile-head">
        <Button
          size='sm'
          contentClassName='p-0'
          icon={arrow_left}
          btnType='secondary'
          onClick={() => navigate(-1)}
        />
        <ChatSettings fromModelProfile/>
      </div>
      <ModelProfile model={model}/>
    </div>
  );
})

export default ModelProfilePage;