import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY, APP_COMPANY_ADDRESS} from "../../../modules/constants";

interface Props {
}

const TermsOfServicePage: FC<Props> = () => {
  return (
     <LegalInformationPageWrapper title='TERMS_OF_SERVICE'>
          <p><b>Welcome to Cherryx.ai (the "application")</b></p>
          <p>These Terms of Service ("TOS") outline the conditions under which you may use the application, operated by {APP_COMPANY}. {APP_COMPANY} located at {APP_COMPANY_ADDRESS}.</p>
          <p>By accessing or utilizing the application, you agree to adhere to these TOS. Your continued use of the application signifies your acceptance of these TOS and all related policies available at https://cherryx.ai/. If you do not agree to these TOS or any other policies, please cease using the application and refrain from accessing our services.</p>
       <p>The application is intended solely for personal, non-commercial use. You agree not to engage in any illegal or unauthorized activities while using the application.
       </p>
       <p>In these TOS, "you" and "your" refer to the user of the application.</p>
       <h4>1. General
       </h4>
       <p>Cherryx.ai is an online chat platform that leverages artificial intelligence ("AI") to create virtual and fictional characters ("AI Companions"). The application allows users to chat with these AI Companions and generate images (collectively referred to as the "Services"). Some features of the application may require registration and the creation of a user account.
       </p>
       <p>To start using the application, you can either choose from a selection of pre-designed AI characters or create your own by customizing its physical appearance and personality traits using our AI algorithms. Once your AI character is set up, you can begin conversations and generate images with your selected AI Companion(s).
       </p>

       <h4>2. Important Reminder
       </h4>
       <p>We want to highlight that all interactions between users and AI Companions on Cherryx.ai are purely fictional and should be regarded as such. The AI Companions are designed to simulate human-like interactions using artificial intelligence, but they do not have real emotions, intentions, or the capability to fulfill promises in the real world. Any elements within these conversations that may appear realistic, such as suggestions for real-life meetings or promises of tangible outcomes, are entirely fictitious and should not be taken seriously. We are not responsible for any confusion or misunderstandings resulting from the fictional nature of the AI conversations. Users should remember that AI Companions exist solely within the digital environment of the platform, and any expectations or beliefs beyond this context are not supported or endorsed by {APP_COMPANY}.
       </p>
       <h4>3. User Account
       </h4>
       <p>Certain features of the application require you to create a user account using your email and a password (referred to as the “Protected Areas”). When accessing these Protected Areas, you must use your registered email address and password. You can sign up or log in using your email address.
       </p>
       <ul>
         <li>You certify that all information provided to create your user account is accurate and truthful, and you have the legal right to submit this information.
         </li>
         <li>You agree to periodically update any information associated with your user account (including, but not limited to, your email, payment details, subscriptions, or other relevant information) to ensure it remains accurate and current. You also agree to keep your user account information confidential, not to share access to your account, and not to disclose your password to anyone.
         </li>
         <li>You are fully responsible for all activities that occur under your user account. Your user account is personal and non-transferable. You may not sell, lend, or share your account with anyone else, whether for commercial purposes or otherwise.
         </li>
         <li>Violations of these TOS, including failing to keep your account information accurate and current, may result in the suspension or termination of your account at our discretion.
         </li>
         <li>We reserve the right to terminate or restrict your user account, or revoke your access to the application or services, at any time if we suspect you have violated these TOS or any other terms or policies published on Cherryx.ai. You agree that we will not be liable to you or any third party for any denial of access to the application or services, changes in costs for third-party services, fees, or the suspension or termination of your account.</li>
       </ul>
       <h4>4. Payment
       </h4>
       <p>After sending five messages, you will be directed to the payment page where you can select from a monthly, 3-month, or annual subscription plan. Payments can be made via bank card or cryptocurrency in specific countries.
       </p>
       <p>Upon completing the payment, you will gain unlimited access to the messaging system and receive 100 tokens per month. These tokens can be used for accessing extended features such as image generation.
       </p>
       <p>Your subscription will automatically renew at the end of each subscription period, extending for the same duration. Payments for the renewal will be processed automatically on the first day of the new period.
       </p>
       <p>We have the exclusive right to offer certain Services that are available only to paid subscribers. Your subscription will begin once the initial payment is received, and payments must be made according to the fee structure specified on our website at the time of purchase. You are responsible for all fees, charges, and applicable taxes related to your subscription.
       </p>
       <h4>5. Restrictions of Conduct and Content
       </h4>
       <h4>5.1 Rules and Restrictions
       </h4>

       <p>When accessing and using the application, you agree to comply with the following guidelines, limitations, and rules:
       </p>
       <p>a. You must not alter, translate, adapt, or reformat the application in any way. b. You are prohibited from deciphering, decompiling, disassembling, reverse-engineering, or attempting to uncover the source code or structure of the software or materials that make up the application (unless such actions are permitted by applicable local law and you have provided us with prior written notice). c. You must not interfere with or bypass any security features of the application or any features that restrict or enforce limitations on its use. d. You must not use the application to gain unauthorized access to our data, systems, or networks, or those of any third party. e. You are not permitted to use the application in any way that could damage, disable, overburden, or impair our systems and networks, or interfere with other users' experience of the application. f. You must avoid using the application in any manner that could, in our sole discretion, expose us or others to potential liability or damages. g. You must not use the application for illegal purposes, to offend others, or to engage in any criminal activity. h. You are prohibited from removing, altering, or obscuring any copyright, trademark notices, trademarks, hyperlinks, or other proprietary rights notices contained within the application. i. You agree to comply with all applicable laws while accessing and using the application, including those of your country or district if you reside outside of the United Kingdom.
       </p>
       <h4>5.2 Content
       </h4>
       <p>You can provide various types of input within the application ("Input") and receive corresponding output from the Services offered by the application based on that Input ("Output"). Together, Input and Output are referred to as "Content." Input is limited to conversations and prompts that are visible only to you within your private account. You affirm that you possess all necessary rights, licenses, and permissions to provide Input within our application.
       </p>
       <p>You retain ownership of any intellectual property rights over the Input you submit. We do not claim ownership of your Input; however, we do require a license from you to utilize it.
       </p>
       <p>By using Cherryx.ai or its associated Services to submit Input protected by intellectual property rights, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate, or create derivative works from your content in accordance with our Privacy Policy.
       </p>
       <p>This license you provide us can be revoked at any time by deleting your Input or your account. However, if we (or our partners) have used your Input in connection with commercial or sponsored content, the license will remain in effect until the associated commercial or post is no longer in use by us.
       </p>
       <p>You also allow us to use your username and other identifying information associated with your account in a manner that is consistent with your privacy preferences and our Privacy Policy
       </p>
       <h4>5.2. Input Use
       </h4>
       <p>As a user of the application, you must ensure that any input you provide adheres to the following rules:
       </p>
       <ul>
         <li><b>Consent and Permission:</b> You must have obtained written consent, release, and/or permission from every identifiable individual featured in your contributions. This includes using their names or likenesses in ways contemplated by the Services and these legal terms.
         </li>
         <li><b>Decency:</b> Contributions must not be obscene, lewd, lascivious, filthy, violent, harassing, defamatory, or otherwise objectionable as determined by us.
         </li>
         <li><b>Respectfulness:</b> Your contributions must not ridicule, mock, disparage, intimidate, or abuse others.
         </li>
         <li><b>Harassment and Threats:</b> Contributions must not be used to harass or threaten any individual, or to promote violence against any person or group.
         </li>
         <li><b>Legal Compliance:</b> Your contributions must comply with all applicable laws, regulations, and rules.
         </li>
         <li><b>Privacy Rights:</b> Contributions must not infringe on the privacy or publicity rights of any third party.
         </li>
         <li><b>Child Protection:</b> Your contributions must not violate any laws concerning child pornography or any laws intended to protect the health or well-being of minors.
         </li>
         <li><b>Offensive Comments:</b> Contributions must not include offensive comments related to race, national origin, gender, sexual preference, or physical disability.
         </li>
         <li><b>Linking to Violations:</b> Your contributions must not contain links to material that violates any provision of these legal terms or any applicable law or regulation.
         </li>
       </ul>
       <h4>5.3. Output Use
       </h4>
       <p>When interacting with the AI Companions and generating output (such as text messages, images), you agree to the following terms:
       </p>
       <ul>
         <li><b>Personal Responsibility:</b> You are fully responsible for the content generated by the AI Companions based on your interactions and inputs.
         </li>
         <li><b>Compliance with Laws:</b> Ensure that the use of generated output complies with all relevant laws and regulations.
         </li>
         <li><b>Ethical Conduct:</b> Avoid using the output for any illegal, unethical, or harmful activities. The AI Companions' generated content should not be used to harass, threaten, or promote violence against any individual or group.
         </li>
         <li><b>Respect Privacy:</b> Do not use generated output in ways that infringe on the privacy or publicity rights of others.
         </li>
         <li><b>Non-commercial Use:</b> The output should be used for personal, non-commercial purposes only.
         </li>
         <li><b>Content Review:</b> Be aware that while we take measures to ensure user safety, the content generated by the AI is based on user input and may not always align with community standards or expectations.
         </li>
         <li><b>Reporting Violations:</b> Report any suspicious or inappropriate content generated by the AI Companions to ensure the platform remains safe and respectful for all users. You can report such content by emailing us at <a
           href="mailto:report@cherryx.ai" target='_blank'>report@cherryx.ai</a>.
         </li>
       </ul>
       <h4>6. Warranties
       </h4>
       <p>You confirm and warrant the following:
       </p>
       <ul>
         <li>If you are agreeing to these Terms of Service (TOS) on behalf of another individual, you have the full authority to bind that individual to these TOS, making them enforceable against both you personally and the other individual.
         </li>
         <li>You are of sufficient legal age in your jurisdiction to enter into a binding contract with us.
         </li>
         <li>If you do not meet the legal age requirements in your jurisdiction, you must not access or use our Services.
         </li>
       </ul>
       <h4>Underage</h4>
       <p>Please refer to the Underage Policy document.
       </p>
       <h4>Intellectual Property
       </h4>
       <p>The intellectual property associated with the application and its embedded materials (including, but not limited to, technology, systems, files, documents, text, photographs, information, images, videos, audios, and software) in Cherryx.ai are owned by or licensed to {APP_COMPANY}. You may download or register for Cherryx.ai to view, use, and display the application and its content on your devices for personal use only.
       </p>
       <ul>
         <li>{APP_COMPANY} provides you with a license for personal use exclusively. This license does not convey any ownership rights. This license will automatically terminate if you violate any of these TOS or any other terms and policies published on https://cherryx.ai, and it may be revoked by us at any time.
         </li>
         <li>To clarify, all intellectual property rights associated with Cherryx.ai, including the AI characters, platform design, logos, and any proprietary software or technology, are the sole property of {APP_COMPANY} or its licensors. Users are forbidden from reproducing, altering, distributing, or using any intellectual property without explicit authorization. Unauthorized use may lead to legal consequences.
         </li>
       </ul>
       <h4>Prohibited Content Policy
       </h4>
       <p>At {APP_COMPANY}, we enforce a strict Prohibited Content Policy to ensure a secure, respectful, and lawful environment on Cherryx.ai ("application"). Users are prohibited from generating or engaging with content that is illegal, hateful, violent, exploitative, or infringing on privacy and intellectual property rights. This includes, but is not limited to, content involving child exploitation, impersonation, or any material that resembles minors. Users are responsible for ensuring compliance with all applicable laws and our terms. For detailed guidelines, please refer to our Prohibited Content Policy. Violations may result in content removal, account suspension, or termination.
       </p>
       <h4>7. Copyright Compliance Policy
       </h4>
       <p>For detailed information on our compliance with copyright laws, please refer to the Copyright Compliance Policy document.
       </p>
       <h4>8. Content Deletion Guidelines
       </h4>
       <p>For our guidelines and procedures regarding the deletion of content, please consult the Content Deletion Guidelines document.
       </p>
       <h4>9. U.S.C. 2257 Exemption
       </h4>
       <p>For detailed information regarding our U.S.C. 2257 Exemption policy, please consult the relevant document.
       </p>
       <h4>10. Content Responsibility
       </h4>
       <p>As a user of the application, you hold full responsibility for any Output produced by the AI Companions, which may include text, images, and other media. The AI Companions generate responses based on your interactions and the specific parameters you set. It is important to understand that {APP_COMPANY} does not oversee or endorse the content created by these AI Companions. Consequently, you accept complete accountability for the AI-generated Output and your actions while utilizing the application.</p>
       <p>It is imperative that your interactions with the AI Companions adhere to all applicable laws, regulations, and these TOS. You must avoid engaging in any activities through the application that are illegal, unethical, or harmful.
       </p>
       <h4>11. Content Moderation
       </h4>
       <p>At {APP_COMPANY}, we prioritize the safety and integrity of our user community. Although interactions between users and AI Companions are typically private, we have implemented a content moderation system to ensure adherence to our Terms and Policies. If this moderation filter identifies content that breaches our terms, we reserve the right to manually review the flagged material and take necessary action, which may include terminating the user's account. This protocol helps us maintain a respectful and secure environment for everyone.
       </p>
       <p>We strive to balance user privacy with community standards and appreciate your cooperation in following our guidelines. While we have the authority to reject or remove any user content that, in our sole discretion, violates these terms, we are not obligated to do so.
       </p>
       <p>If you encounter any content that you believe violates these Terms, please contact us at <a href="mailto:report@cherryx.ai" target='_blank'>report@cherryx.ai</a>.
       </p>
       <h4>12. Accuracy Disclaimer
       </h4>
       <p>By utilizing our Services, you accept that the content generated on demand by artificial intelligence may not always be accurate. Due to the nature of AI-generated content, we cannot ensure the precision of the results. Nonetheless, we are committed to continuously enhancing our tool to offer increasingly higher quality service.
       </p>
       <h4>13. Liability
       </h4>
       <p>The application is provided on an "as-is" and "as available" basis, and your use of it is at your own risk. We make no representations or warranties of any kind, whether express, implied, statutory, or otherwise, including but not limited to warranties of merchantability, fitness for a particular purpose, satisfactory quality, non-infringement, and title, to the fullest extent permitted by applicable law.
       </p>
       <p>We do not guarantee that:
       </p>
       <p>A. The application, or the results obtained from its use, will be timely, error-free, secure, or uninterrupted; B. The application will meet your specific requirements; C. The accuracy, likely results, or reliability of the use of the materials on our website, or otherwise relating to such materials or on any resources linked to our website; D. Any errors or malfunctions in the application will be corrected.
       </p>
       <p>We shall not be liable to you or any third party for any direct, indirect, special, incidental, consequential, exemplary, liquidated, or punitive damages, or any other damages, including but not limited to loss of profit, revenue, or business, cost of substitute procurement, arising from your use of, or inability to use, the application, even if we have been advised of the possibility of such damages. Under no circumstances shall we be held liable for any delay or failure in performance resulting from causes beyond our reasonable control.
       </p>
       <h4>14. Governing Law and Dispute Resolution
       </h4>
       <p>These Terms of Service (TOS) are governed by and interpreted in accordance with the laws of the United Kingdom, regardless of your location and without regard to conflict of law principles.
       </p>
       <p>Any disputes arising from or related to these TOS or any other terms and policies, including issues concerning their existence, validity, or termination, will be exclusively subject to the jurisdiction of the courts of the United Kingdom.
       </p>
       <h4>15. Links to Third-Party Websites and Services
       </h4>
       <p>The application may contain links to, or provide access to, third-party websites and services. The inclusion of these links does not imply our endorsement or recommendation, nor do we guarantee their safety or that they will meet your expectations. We are not responsible for any damage, loss, or other impacts, directly or indirectly, resulting from the use of any content, goods, or services available through such third-party websites and services.
       </p>
       <p>It is your responsibility to take appropriate precautions to ensure that any material you choose to use or download, whether from the application or a third-party site, is free from harmful elements such as viruses, worms, Trojan horses, and other destructive items. We do not assume responsibility for any transmission or material, nor for any viral infection of your computer equipment or software, or any other types of damage related to your access, use, or browsing of third-party sites or content. If you choose to access a third-party website, you do so entirely at your own risk and should review the terms of use and privacy policies governing those sites.
       </p>
       <h4>16. Changes to Terms and Policies
       </h4>
       <p>We reserve the right to modify these Terms of Service (TOS) and any other related terms and policies at our sole discretion. Updates or changes will be effective immediately upon making the revised versions available through the application. We may notify you of these changes by making the updated documents accessible via the application. We encourage you to periodically review these documents to stay informed about any updates. The “Date of Revision” at the top of the page indicates when the documents were last updated. If you do not agree with the revised terms and policies, you should discontinue using the application immediately. Your continued use of the application after any changes signifies your acceptance and consent to the updated terms.
       </p>
       <p>We also reserve the right to alter the scope of the services and adjust the applicable fees at any time and for any reason, without prior notice. Additionally, we may cease or limit the provision of services to any user, and we retain the authority to discontinue the services and support without any prior notice.
       </p>
       <h4>17. Termination and Assignment
       </h4>
       <p>These Terms of Service (TOS) and any other related terms and policies will remain in effect until terminated by either you or us, as described below. You may terminate these TOS at any time by discontinuing your access and use of the application. If you are a paid subscriber, the handling of any subsequent fees will be governed by the rules of the respective payment processor recognized by us. We reserve the right to terminate these TOS and your access to the application at any time, with or without notice, for any reason, including any suspected violation of these TOS or other related terms and policies.
       </p>
       <p>We also retain the right to assign, transfer, or subcontract our services to third parties. Notice of such assignment will be posted within the application. Your continued use of the application following such notice indicates your consent to the assignment.
       </p>
       <h4>18. Miscellaneous
       </h4>
       <p>Should any provision of these TOS or any other terms and policies be deemed unlawful, void, or unenforceable for any reason, such provision will be adjusted to the extent necessary so that the remaining provisions of these TOS will remain in full effect and enforceable. The invalidity of any specific provision will not impact the validity and enforceability of the remaining provisions, which will be applied to the fullest extent permissible to reflect the parties' intentions.
       </p>
       <p>These TOS, along with the Privacy Policy and other published terms and policies, form the complete agreement between you and us concerning your access to and use of the application. This agreement supersedes any prior or contemporaneous written or oral agreements between us regarding such matters.
       </p>
       <p>We will handle and process your information and technical data in accordance with our Privacy Policy.
       </p>
       <h4>19. Complaint Policy
       </h4>
       <p>For detailed information on our procedures for handling complaints, please refer to the Complaint Policy document.
       </p>
       <h4>20. Fulfillment Policy
       </h4>
       <h4>20.1 Cancellation
       </h4>
       <p>Details regarding your subscription, including its start date, end date, and type of subscription plan, can be found under "My Profile" and then "Settings" in your account.
       </p>
       <ul>
         <li>You can cancel your subscription at any time by navigating to "Settings" in your account and clicking the "Unsubscribe" button. If you choose to cancel, your subscription will remain active until the end of the current billing period, and you will not be charged for the next period.
         </li>
       </ul>
       <h4>20.2 Access Upon Cancellation
       </h4>
       <p>After canceling your subscription, you will retain access to the platform and its features until the end of the current billing cycle. Once this period concludes, your access will be restricted. You may choose to resubscribe at any time.
       </p>
       <h4>20.3 Refund Policy
       </h4>
       <ul>
         <li>You are eligible to request a refund within 24 hours of making a payment. Refund requests made after 24 hours will not be honored. Additionally, if you have used more than 20 tokens, your refund request will be denied regardless of when it was made. Refunds are only available for subscriptions purchased via card and cannot be issued for technical issues on the user's side. To request a refund, please contact us at <a
           href="mailto:support@cherryx.ai" target='_blank'>support@cherryx.ai</a>.
         </li>
       </ul>
     </LegalInformationPageWrapper>
  )
}

export default TermsOfServicePage;