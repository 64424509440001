import { RestAPI } from './rest';
import AppStore from '../store/AppStore';
import i18next from 'i18next';

const isDev = !!window.location.host.match(/^(localhost|127\.|192\.)/);
const backendUrl = isDev ? 'http://192.168.1.223:8010' : `https://app.cherryx.ai`;
// const backendUrl =   `https://app.cherryx.ai`;
// const backendUrl = isDev ? 'http://redstream.by:8010' : `https://app.cherryx.ai`;
const wsUrl = isDev ? 'ws://192.168.1.223:5000/connection/websocket' : 'wss://wss.cherryx.ai/connection/websocket';
const API = new RestAPI(backendUrl, isDev);

API.setAuthErrorHandler(() => {
  AppStore.logout();
  throw new Error();
  // window.localStorage.removeItem('token');
  // window.location.href = '/';
});

API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = i18next.language;
});

export { API, isDev, backendUrl, wsUrl };
