import React, {FC, useEffect, useRef, useState} from 'react';
import NameAge from "../NameAge";
import Button from "../Button";
import message from "../../assets/icons/message.svg";
import {IModel, IPhoto} from "../../modules/rest";
import {thumb} from "../../modules/utils";
import ImageComponent from "../ImageComponent";
import {useTranslation} from "react-i18next";
import {API} from "../../modules/api";

interface Props {
  className?: string;
  chatBtn?: boolean;
  onClick?: () => void;
  hideAbout?: boolean;
  active?: boolean;
  animate?: boolean;
  category?: string;
  model?: IModel;
  imageSize?: number;
}


const ModelCard: FC<Props> = ({
                                className,
                                chatBtn,
                                onClick,
                                hideAbout,
                                active,
                                category,
                                model,
                                imageSize,
                                animate,
                              }) => {
  const {t} = useTranslation();
  const _timer: any = useRef(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [gallery, setGallery] = useState<IPhoto[]>();
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  useEffect(() => {
    if (focused && ready && gallery?.length) {
      _timer.current = setTimeout(start, 100);
    } else {
      clear();
    }
    return () => {
      clear();

    }
  }, [focused, ready, gallery?.length]);


  const clear = () => {
    clearTimeout(_timer?.current);
  }

  const fetchGallery = async () => {
    if (ready || loading) return null;
    try {
      setLoading(true);
      const res = await API.Models.getMainPhotos(model?.id!);
      let [, ...newArr] = res;
      setGallery(newArr);
      setReady(true);
    } catch (e) {
      setLoading(false);
    }
  }

  const start = () => {
    clear();
    _timer.current = setTimeout(start, 3000);
    const len = gallery?.length || 0;
    setActiveImageIdx(prevState => {
      if (prevState === len) return 0;
      return prevState + 1;
    })
  }

  if (!model) return <div className={`model-card${active ? ' active' : ''} ${className || ''}`} onClick={onClick}></div>

  return (
    <div
      className={`model-card${active ? ' active' : ''}${animate ? ' animate' : ''} ${className || ''}`}
      onClick={onClick}
      onMouseEnter={() => {
        fetchGallery()
        setFocused(true)
      }}
      onMouseLeave={() => setFocused(false)}
    >
      {category ?
        <div className="model-card-category">
          <span className='letter-uppercase text-truncate'>{t(category.toUpperCase())}</span>
        </div> : null}
      {model.mainPhotoImage ?
        <ImageComponent
          className={activeImageIdx === 0 ? 'active' : ''}
          src={thumb(model?.mainPhotoImage.id, imageSize || 800)}
          preloadSrc={thumb(model?.mainPhotoImage.id, 50)}
          alt={'model_' + model.id}
        />
        : null
      }
      {gallery && animate
        ?
        <div className="model-card-images-list">
          {gallery.map((item, i) => (
            <img
              key={i}
              src={thumb(item.photo.id, imageSize || 800)}
              className={`${activeImageIdx === i + 1 ? 'active ' : ''}${className || ''}`}/>
          ))}
        </div>
        :
        null
      }

      {!hideAbout &&
        <>
          <div className="model-card-bg"/>
          <div className="model-card-about">
            <NameAge name={model.firstName} age={model.age}/>
            {model.aboutLocal ? <div className='text-truncate-2 text-14 mt-3'>{model.aboutLocal}</div> : null}
            {chatBtn
              ?
              <Button
                icon={message}
                iconAbsolute
                className='w-100 mt-4 model-card-chat-btn'
                size='sm'
                title='CHAT'
              />
              :
              null
            }

          </div>
        </>
      }
      {chatBtn
        ?
        <Button
          circle
          icon={message}
          className='model-card-chat-btn-mobile'
          size='sm'
        />
        :
        null
      }
    </div>

  );
}

export default ModelCard;