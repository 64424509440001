import React, {FC} from 'react';
import { IMessage} from "../../modules/rest";
import Avatar from "../Avatar";
import {  thumbAvatar} from "../../modules/utils";
import {ReactSVG} from "react-svg";
import image from '../../assets/icons/image.svg';
import BoardMessage from "./BoardMessage";
import {useNavigate} from "react-router-dom";

interface Props {
  data?: IMessage;
  typing?: boolean;
  uploading?: boolean;
  avatarId?: string;
  hideAvatar?: boolean;
  userStub?: boolean;
  message?: string;
  className?: string;
  handleAuth?: () => void;
}

const Message: FC<Props> = ({typing, message, className, data, hideAvatar, avatarId, uploading, userStub, }) => {
  const navigate = useNavigate();


  if (typing) return (
    <div className='message-container'>
      <div className={`message-typing ${data?.sender || ''} ${className || ''}`}>
        <div className="message-typing-item"/>
        <div className="message-typing-item"/>
        <div className="message-typing-item"/>
      </div>
      {!hideAvatar && <Avatar image={thumbAvatar(avatarId, 128)} size={'xs'}/>}
    </div>
  )
  if (uploading) return (
    <div className='message-container'>
      <div className={`message-uploading ${data?.sender || ''} ${className || ''}`}>
        <ReactSVG src={image} className='react-icon'/>
        <div className="message-uploading-progress"/>
      </div>
      {!hideAvatar && <Avatar image={thumbAvatar(avatarId, 128)} size={'xs'}/>}
    </div>
  )
  if (message) return (
    <div className={`message-container ${data?.sender || ''} ${className || ''}`}>
      <div className="message">
        <div className='p-2'>{message}</div>
      </div>
    </div>
  )
  return <BoardMessage
    onPremiumNavigate={() => navigate('/premium/subscription')}
    userStub={userStub}
    data={data}
    className={className}
    hideAvatar={hideAvatar}
    avatarId={avatarId}
  />
}

export default Message;